@font-face {
    font-family: "BerkelyMonoRegular";
    src: local("BerkeleyMono-Regular.ttf");
}
@font-face {
    font-family: "BerkelyMonoBold";
    src: local("BerkeleyMono-Bold.ttf");
}

*, html {
    font-family: 'BerkelyMonoRegular', sans-serif;
}

[x-cloak] { display: none !important; }



@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
